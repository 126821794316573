export const environment = {
  appHost: 'https://dev.hub.veratrak.com',
  apiHost: 'https://2k8j0519qc.execute-api.eu-west-1.amazonaws.com/Prod',
  awsHost:
    'https://d-936748b868.awsapps.com/start/#/console?account_id=804798393054&destination=',
  cognito: {
    poolName: 'watchtower-dev',
    clientId: '5fc09hajnk9god42tamskieich',
    redirect: 'https://dev.watchtower.hub.veratrak.com',
  },
};
